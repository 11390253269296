import {Component} from '@angular/core';

@Component({
  selector: 'cull-getting-started',
  templateUrl: './getting-started.component.html',
  styleUrls: ['./getting-started.component.scss']
})
export class GettingStartedComponent {

  constructor() {
  }

}
