import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {PopoverRef} from "../../../../../../@vex/components/popover/popover-ref";
import {AuthService} from "@auth0/auth0-angular";
import {AccountService} from "../../../../services/account.service";

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  constructor(private cd: ChangeDetectorRef,
              private auth: AuthService,
              public accountService: AccountService,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>) {
  }

  ngOnInit() {
  }

  logout() {
    this.auth.logout({returnTo: document.location.origin});
    this.close();
  }

  close() {
    this.popoverRef.close();
  }
}
