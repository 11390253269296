import {Component, Inject, LOCALE_ID, Renderer2} from '@angular/core';
import {ConfigService} from '../@vex/config/config.service';
import {Settings} from 'luxon';
import {DOCUMENT} from '@angular/common';
import {Platform} from '@angular/cdk/platform';
import {NavigationService} from '../@vex/services/navigation.service';
import {LayoutService} from '../@vex/services/layout.service';
import {ActivatedRoute} from '@angular/router';
import {SplashScreenService} from '../@vex/services/splash-screen.service';
import {VexConfigName} from '../@vex/config/config-name.model';
import {MatIconRegistry, SafeResourceUrlWithIconOptions} from '@angular/material/icon';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {AccountService} from "./lib/services/account.service";

@Component({
  selector: 'vex-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  isDesktop$ = this.layoutService.isDesktop$;

  constructor(private configService: ConfigService,
              private renderer: Renderer2,
              private platform: Platform,
              @Inject(DOCUMENT) private document: Document,
              @Inject(LOCALE_ID) private localeId: string,
              private layoutService: LayoutService,
              private route: ActivatedRoute,
              private navigationService: NavigationService,
              private splashScreenService: SplashScreenService,
              private readonly matIconRegistry: MatIconRegistry,
              private accountService: AccountService,
              private readonly domSanitizer: DomSanitizer) {
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, 'is-blink');
    }

    this.matIconRegistry.addSvgIconResolver(
      (
        name: string,
        namespace: string
      ): SafeResourceUrl | SafeResourceUrlWithIconOptions | null => {
        switch (namespace) {
          case 'mat':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/material-design-icons/two-tone/${name}.svg`
            );

          case 'logo':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/logos/${name}.svg`
            );

          case 'flag':
            return this.domSanitizer.bypassSecurityTrustResourceUrl(
              `assets/img/icons/flags/${name}.svg`
            );
        }
      }
    );

    /**
     * Customize the template to your needs with the ConfigService
     * Example:
     *  this.configService.updateConfig({
     *    sidenav: {
     *      title: 'Custom App',
     *      imageUrl: '//placehold.it/100x100',
     *      showCollapsePin: false
     *    },
     *    footer: {
     *      visible: false
     *    }
     *  });
     */
    this.configService.setConfig(VexConfigName.ikaros);


    // this.navigationService.items = [
    //   {
    //     type: 'subheading',
    //     label: 'Dashboards',
    //     children: [
    //       {
    //         type: 'link',
    //         label: 'Analytics',
    //         route: '/',
    //         icon: 'mat:insights',
    //         routerLinkActiveOptions: {exact: true}
    //       }
    //     ]
    //   },
    //   {
    //     type: 'subheading',
    //     label: 'Foo',
    //     children: [
    //       {
    //         type: 'link',
    //         label: 'Analytics',
    //         route: '/',
    //         icon: 'mat:insights',
    //         routerLinkActiveOptions: {exact: true}
    //       }
    //     ]
    //   }
    // ];

    accountService.listenForAuthentication();
  }
}
