import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {HomeComponent} from "./pages/home/home.component";
import {ProductKeysComponent} from "./pages/product-keys/product-keys.component";
import {UsageReportComponent} from "./pages/usage-report/usage-report.component";
import {AuthGuard} from "@auth0/auth0-angular";
import {GettingStartedComponent} from "./pages/docs/getting-started/getting-started.component";
import {AccountGuard} from "./lib/guards/account-guard.service";
import {AccountSetupRequiredComponent} from "./pages/errors/account-setup-required/account-setup-required.component";
import {DownloadsComponent} from "./pages/downloads/downloads.component";

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [AccountGuard],
  },
  {
    path: 'docs/getting-started',
    component: GettingStartedComponent,
    canActivate: [AuthGuard, AccountGuard],
  },
  {
    path: 'product-keys',
    component: ProductKeysComponent,
    canActivate: [AuthGuard, AccountGuard],
  },
  {
    path: 'usage-report',
    component: UsageReportComponent,
    canActivate: [AuthGuard, AccountGuard],
  },
  {
    path: 'downloads',
    component: DownloadsComponent,
  },
  {
    path: 'errors/account-setup-required',
    component: AccountSetupRequiredComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
