<mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
<div *ngIf="!loading">
  <div class="mb-3">
    <a class="mr-5" mat-flat-button color="primary" [href]="release.url" *ngFor="let release of manifest?.current">
      Download v{{release.version}} for {{release.platform}}
    </a>
  </div>
  <div *ngIf="enableAdvancedOptions">
    <a mat-flat-button (click)="toggleOldVersions()" class="mt-5">
      <mat-icon svgIcon="mat:unfold_more" *ngIf="!displayOldVersions"></mat-icon>
      <mat-icon svgIcon="mat:unfold_less" *ngIf="displayOldVersions"></mat-icon>
      Older Releases
    </a>
    <div *ngIf="displayOldVersions" class="mt-5">
      <table mat-table [dataSource]="manifest.prior" class="mat-elevation-z2 cull-full-width">
        <ng-container matColumnDef="version">
          <th mat-header-cell *matHeaderCellDef>Version</th>
          <td mat-cell *matCellDef="let release"> v{{release.version}} </td>
        </ng-container>
        <ng-container matColumnDef="platform">
          <th mat-header-cell *matHeaderCellDef>Platform</th>
          <td mat-cell *matCellDef="let release"> {{release.platform}} </td>
        </ng-container>
        <ng-container matColumnDef="download">
          <th mat-header-cell *matHeaderCellDef class="text-right">Download</th>
          <td mat-cell *matCellDef="let release" class="text-right">
            <a mat-button [href]="release.url">Download</a>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>

