<div class="my-12 container flex flex-col md:flex-row items-stretch md:items-start gap-6">
  <div @fadeInUp class="card p-6 flex-1 flex flex-col items-center">
    <div class="inline-block p-6 rounded-full text-primary mx-auto flex items-center justify-center bg-primary/10">
      <mat-icon class="icon-5xl" svgIcon="mat:format_shapes"></mat-icon>
    </div>

    <h2 class="headline my-6">OCR</h2>

    <div class="body-1 h-32">
      <div class="mb-2">Extract text from your scanned documents</div>
      <div class="mb-2">Hundreds of supported languages</div>
      <div class="mb-2">Handwriting recognition</div>
    </div>

    <div class="font-bold text-secondary">
      Starting at
    </div>
    <div class="display-1">
      $0.005/page
    </div>
  </div>

  <div @fadeInUp class="card p-6 flex-1 flex flex-col items-center">
    <div class="inline-block p-6 rounded-full text-primary mx-auto flex items-center justify-center bg-primary/10">
      <mat-icon class="icon-5xl" svgIcon="mat:speaker_notes"></mat-icon>
    </div>

    <h2 class="headline my-6">Text Overlays</h2>

    <div class="body-1 h-32">
      <div class="mb-2">Insert the OCR text back into your PDF documents</div>
      <div class="mb-2">Create searchable PDFs</div>
    </div>

    <div class="font-bold text-secondary">
      Starting at
    </div>
    <div class="display-1">
      $0.005/page
    </div>
  </div>

  <div @fadeInUp class="card p-6 flex-1 flex flex-col items-center">
    <div class="inline-block p-6 rounded-full text-primary mx-auto flex items-center justify-center bg-primary/10">
      <mat-icon class="icon-5xl" svgIcon="mat:picture_as_pdf"></mat-icon>
    </div>

    <h2 class="headline my-6">Image to PDF</h2>

    <div class="body-1 h-32">
      <div class="mb-2">Combine your scanned images into PDF documents</div>
      <div class="mb-2">Opticon (OPT) file support</div>
    </div>

    <div class="font-bold text-secondary">
      Starting at
    </div>
    <div class="display-1">
      $0.0025/page
    </div>
  </div>

  <div @fadeInUp class="card p-6 flex-1 flex flex-col items-center">
    <div class="inline-block p-6 rounded-full text-primary mx-auto flex items-center justify-center bg-primary/10">
      <mat-icon class="icon-5xl" svgIcon="mat:picture_as_pdf"></mat-icon>
    </div>

    <h2 class="headline my-6">Entity Extraction</h2>

    <div class="body-1 h-32">
      <div class="mb-2">Form key-value pairs from PDF/Images</div>
      <div class="mb-2">Tables and Spreadsheets from PDF/Images</div>
    </div>

    <div class="font-bold text-secondary">
      Starting at
    </div>
    <div class="display-1">
      $0.35/page
    </div>
  </div>
</div>
