import {BehaviorSubject, distinctUntilChanged, Observable} from "rxjs";
import {ProductKey} from "../model/product-key.model";
import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {DownloadManifest} from "../model/download-manifest.model";
import {OperationUsage, OperationUsageType} from "../model/operation-usage.model";
import {map} from "rxjs/operators";
import {AccessRequestForm} from "../model/access-request-form.model";
import {Account} from "../model/account.model";
import {User} from "../model/user.model";
import {AuthService} from "@auth0/auth0-angular";


@Injectable({
  providedIn: 'root'
})
export class AccountService {

  constructor(private http: HttpClient, private auth: AuthService) {
  }

  user$ = new BehaviorSubject<User|null>(null);

  /**
   * auth0's http interceptor is causing duplicate emits which is fixed in v2 beta
   * use distinctUntilChanged until we can upgrade:
   * https://github.com/auth0/auth0-angular/issues/286
   */
  authUser$ = this.auth.user$.pipe(distinctUntilChanged((prev, curr) => prev === curr));

  getDownloadManifest(channel): Observable<DownloadManifest> {
    return this.http.get<DownloadManifest>(this.getManifestUrl(channel));
  }

  addApiKey(name: string): Observable<ProductKey> {
    const url = environment.apiUrl + "/accounts/api/v1/apiKeys";
    const options = {headers: this.buildHeaders()};
    const body = {description: name};
    return this.http.post<ProductKey>(url, body, options);
  }

  listApiKeys(): Observable<ProductKey[]> {
    const url = environment.apiUrl + "/accounts/api/v1/apiKeys";
    const options = {headers: this.buildHeaders()};
    return this.http.get<ProductKey[]>(url, options);
  }

  deleteApiKey(id: String): Observable<void> {
    const url = environment.apiUrl + "/accounts/api/v1/apiKeys/" + id;
    const options = {headers: this.buildHeaders()};
    return this.http.delete<void>(url, options);
  }

  reportOperationUsage(month: Date, type: OperationUsageType): Observable<OperationUsage[]> {
    const url = environment.apiUrl + "/accounts/api/v1/reports/operationUsage";
    return this.http.get<OperationUsage[]>(url, {
      headers: this.buildHeaders(),
      params: new HttpParams()
        .append("month", month.toISOString())
        .append("operation", type)
    }).pipe(map(records => {
      for (const r of records) {
        let zone = new Date().getTimezoneOffset() / 60;
        r.period = new Date(r.period + "T00:00:00.000" + zone);
      }
      return records;
    }));
  }

  getUser(): Observable<User> {
    const url = environment.apiUrl + "/accounts/api/v1/users/current";
    const options = {headers: this.buildHeaders()};
    return this.http.get<any>(url, options);
  }

  getAccount(): Observable<Account> {
    const url = environment.apiUrl + "/accounts/api/v1/account";
    const options = {headers: this.buildHeaders()};
    return this.http.get<any>(url, options);
  }

  requestAccess(user: AccessRequestForm): Observable<any> {
    const url = "https://forms.hubspot.com/uploads/form/v2/339990/8f087756-e05b-4cac-9b9e-cdd479411c9d";
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    const body = new URLSearchParams();
    body.set('email', user.email);
    body.set('firstname', user.firstName);
    body.set('lastname', user.lastName);
    body.set('phone', user.phone);
    body.set('company', user.company);
    body.set('city', user.city);
    body.set('state', user.state);
    body.set('zip', user.zip);
    return this.http.post(url, body, options);
  }

  listenForAuthentication() {
    this.authUser$.subscribe(value => {
      if (value) {
        this.getUser().subscribe({
          next: u => {
            this.user$.next(u);
          },
          error: err => {
            console.error("Failed to load user: ", err);
            this.user$.next(null);
          }
        })
      } else {
        this.user$.next(null);
      }
    });
  }

  protected buildHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      'x-client-version': 'AccountService:' + window.navigator.userAgent
    });
  }

  protected getManifestUrl(channel: string): string {
    let defaultUrl = 'https://storage.googleapis.com/com-platinumids-public/cullable-ocr-client/index.json';
    switch (channel) {
      case 'stable':
        return defaultUrl;
      case 'early-access':
        return 'https://storage.googleapis.com/com-platinumids-public/cullable-ocr-client/early-access.json';
      case 'dev':
        return 'https://storage.googleapis.com/com-platinumids-public/cullable-ocr-client/dev.json';
      default:
        console.warn("Invalid release channel: " + channel);
        return defaultUrl;
    }
  }
}
