<vex-page-layout mode="card">
  <vex-page-layout-header class="flex flex-col items-start justify-center">
    <div class="container">
      <div>
        <h1 class="title mt-0 mb-1">Product Key Management</h1>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="container">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="card p-3">
      <table mat-table [dataSource]="keys" class="w-full flex-auto">
        <ng-container matColumnDef="created">
          <th mat-header-cell *matHeaderCellDef>Created</th>
          <td mat-cell *matCellDef="let key">
            {{key.created|date:'short'}}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let key">
            {{key.description}}
          </td>
        </ng-container>
        <ng-container matColumnDef="value">
          <th mat-header-cell *matHeaderCellDef>Key Preview</th>
          <td mat-cell *matCellDef="let key">
            <a class="copy-clipboard" (click)="copyKeyValue(key)" matTooltip="Copy key to clipboard">
              <mat-icon svgIcon="mat:content_copy" class="text-primary">Copy</mat-icon>
              {{key.id}}...
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let key">
            <button mat-icon-button aria-label="Delete" (click)="deleteKey(key.id)" [disabled]="busy || loading">
              <mat-icon svgIcon="mat:delete" class="text-warn">Delete</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!--suppress HtmlUnknownBooleanAttribute -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="2">No keys configured..</td>
        </tr>
      </table>
      <div class="my-10">
        <button mat-raised-button color="primary" (click)="openAddKeyDialog()" [disabled]="maxKeysReached || busy || loading">
          <span *ngIf="maxKeysReached">Max keys reached ({{maxKeys}})</span>
          <span *ngIf="!maxKeysReached">
          <mat-icon svgIcon="mat:add"></mat-icon>
          Add Key
        </span>
        </button>
      </div>
      <p class="my-6 text-center text-secondary">
        <mat-icon svgIcon="mat:info" color="primary" class="cull-text-icon"></mat-icon>
        Product keys are used to identify the running application. You can create a new key per installation or perhaps share them by organizational units.
      </p>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
