export interface OperationUsage {
  period: Date;
  label: string;
  quantity: number;
}

export enum OperationUsageType {
  PageOcr = "PAGE_OCR",
  PageOverlay = "PAGE_OVERLAY",
  PageImageAggregate = "PAGE_IMAGE_AGGREGATE",
  EntityExtraction = "ENTITY_EXTRACT",
}

export interface OperationUsageSeries {
  name: any;
  series: {
    name: string;
    value: number;
  }[];
}
