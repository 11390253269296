<vex-page-layout mode="card">
  <vex-page-layout-header class="h-6"></vex-page-layout-header>

  <vex-page-layout-content class="container">
    <div class="card p-2 md:p-6">
      <div class="text-center">
        <h1 class="display-1 m-0">Cullable Desktop</h1>
        <h3 class="title mt-0 text-secondary">Powerful tools for document discovery</h3>
      </div>
      <div class="md:flex mt-10">
        <div class="md:w-1/2 text-center">
          <img src="/assets/img/screenshots/cullable-desktop-screenshot.png" alt="Cullable Desktop Screenshot"/>
        </div>
        <div class="md:w-1/2">
          <h3 class="title mt-5 text-secondary">Fast</h3>
          <p>
            With unmatched speeds, complete your document workloads in record time!
          </p>
          <h3 class="title mt-0 text-secondary">Easy</h3>
          <p>
            No complicated software to learn. Simply load your documents and let it run.
          </p>
          <h3 class="title mt-0 text-secondary">Affordable</h3>
          <p>
            No contracts or long term commitments. Pay only for what you use.
          </p>
          <h3 class="title mt-0 text-secondary">Accurate</h3>
          <p>
            State of the art OCR means low error rates even with difficult content such as hand writing, upside-down text and much, much more.
          </p>
        </div>
      </div>
      <div class="mt-20">
        <cull-features></cull-features>
      </div>
      <div class="mt-20" *ngIf="displaySignUp">
        <div class="text-center mb-5">
          <h1 class="display-1 m-0">Sound Good?</h1>
          <h3 class="title mt-0 text-secondary">Sign up for early access</h3>
        </div>
        <cull-register class="object-center"></cull-register>
      </div>
    </div>
  </vex-page-layout-content>

</vex-page-layout>
