import {Component, Input, OnInit} from '@angular/core';
import {DownloadManifest} from "../../model/download-manifest.model";
import {AccountService} from "../../services/account.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ActivatedRoute, ParamMap} from "@angular/router";

@Component({
  selector: 'cull-release-manifest',
  templateUrl: './release-manifest.component.html',
  styleUrls: ['./release-manifest.component.scss']
})
export class ReleaseManifestComponent implements OnInit {

  @Input() enableAdvancedOptions = false;

  manifest: DownloadManifest;
  channel = "stable";
  loading = true;
  displayOldVersions = false;
  displayedColumns = ['version', 'platform', 'download']

  constructor(private accountService: AccountService, private snackbar: MatSnackBar, private route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: ParamMap) => {
      if (params['channel']) {
        this.channel = params['channel'];
      }
      this.loadReleases();
    });
  }

  toggleOldVersions() {
    this.displayOldVersions = !this.displayOldVersions;
  }

  protected loadReleases() {
    this.accountService.getDownloadManifest(this.channel).subscribe({
      next: value => {
        this.manifest = value;
      },
      error: err => {
        console.error("Unable to download release manifest", err);
        this.snackbar.open("Unable to download release manifest", "Close");
      },
      complete: () => this.loading = false
    });
  }

}
