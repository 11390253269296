import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ConfigService} from "../../../../@vex/config/config.service";
import {LayoutService} from "../../../../@vex/services/layout.service";
import {AuthService} from "@auth0/auth0-angular";
import {AccountService} from "../../services/account.service";

@Component({
  selector: 'cull-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = [];

  isHorizontalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$: Observable<boolean> = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$: Observable<boolean> = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));
  userVisible$: Observable<boolean> = this.configService.config$.pipe(map(config => config.toolbar.user.visible));

  constructor(private layoutService: LayoutService,
              private configService: ConfigService,
              private authService: AuthService,
              private accountService: AccountService) { }

  ngOnInit(): void {
    this.accountService.user$.subscribe({
      next: value => {
        if (value) {
          this.navigationItems = [
            {
              type: 'subheading',
              label: 'Documentation',
              children: [
                {
                  type: 'link',
                  label: 'Getting Started',
                  route: '/docs/getting-started',
                  icon: 'mat:inventory',
                  routerLinkActiveOptions: { exact: true }
                },
              ]
            }
          ];
        }
      }
    });
  }



  openSidenav(): void {
    this.layoutService.openSidenav();
  }
}
