<div #userToolbar>
  <div *ngIf="auth.user$ | async as user; else showLogin"
       (click)="showUserMenuPopover()"
       [class.bg-hover]="menuOpen"
       class="flex items-center rounded cursor-pointer relative trans-ease-out select-none py-1 pr-1 pl-3 hover:bg-hover"
       matRipple>
    <div class="body-1 font-medium leading-snug ltr:mr-3 rtl:ml-3 hidden sm:block">{{user.name}}</div>
    <div class="rounded-full h-9 w-9 flex items-center justify-center text-primary bg-primary/10">
      <mat-icon svgIcon="mat:person"></mat-icon>
    </div>
  </div>

  <ng-template #showLogin>
    <button (click)="auth.loginWithRedirect()" mat-button>
      Log in
      <mat-icon svgIcon="mat:login" class="cull-text-icon ml-1"></mat-icon>
    </button>
  </ng-template>
</div>

