<!--suppress HtmlUnknownBooleanAttribute -->
<vex-page-layout mode="card">
  <vex-page-layout-header class="flex flex-col items-start justify-center">
    <div class="container">
      <div>
        <h1 class="title mt-0 mb-1">Operation Usage Reporting</h1>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="container">
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
    <div class="card p-3">
      <div class="headline mb-5 text-center text-secondary">
        {{period|date:'MMM yyyy'}}
      </div>
      <hr class="mb-5"/>
      <form class="grid  grid-cols-3 grid-rows-1 mb-5">
        <div class="mt-4">
          <button mat-flat-button (click)="loadReport(prevPeriod)" [disabled]="loading" class="period-navigation">
            <mat-icon svgIcon="mat:navigate_before"></mat-icon>
            {{prevPeriod|date: 'MMM'}}
          </button>
        </div>
        <div class="text-center">
          <mat-form-field>
            <mat-label>Operation Type</mat-label>
            <mat-select [(value)]="operationType" (selectionChange)="loadReport(period)" [disabled]="loading">
              <mat-option [value]="pageOcr">Page OCR</mat-option>
              <mat-option [value]="pageOverlay">Page Overlay</mat-option>
              <mat-option [value]="pageImageAggregate">Image to PDF</mat-option>
              <mat-option [value]="entityExtraction">Entity Extraction</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="text-end mt-4">
          <button mat-flat-button (click)="loadReport(nextPeriod)" [disabled]="loading" class="period-navigation">
            {{nextPeriod|date: 'MMM'}}
            <mat-icon svgIcon="mat:navigate_next"></mat-icon>
          </button>
        </div>
      </form>
      <div class="mb-5" *ngIf="series?.length">
        <ngx-charts-bar-vertical-stacked
          [results]="series"
          [gradient]="false"
          [xAxis]="true"
          [yAxis]="true"
          [legend]="true"
          [showXAxisLabel]="false"
          [showYAxisLabel]="false"
          [animations]="true">
        </ngx-charts-bar-vertical-stacked>
      </div>
      <div>
        <table mat-table [dataSource]="dataSource" class="w-full">
          <ng-container matColumnDef="period">
            <th mat-header-cell *matHeaderCellDef>Date</th>
            <td mat-cell *matCellDef="let element"> {{element.period|date: 'M/d/yy'}} </td>
          </ng-container>
          <ng-container matColumnDef="label">
            <th mat-header-cell *matHeaderCellDef>Label</th>
            <td mat-cell *matCellDef="let element"> {{element.label}} </td>
          </ng-container>
          <ng-container matColumnDef="quantity">
            <th mat-header-cell *matHeaderCellDef>Quantity</th>
            <td mat-cell *matCellDef="let element"> {{element.quantity|number}} </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">
              No records found
            </td>
          </tr>
        </table>
        <mat-paginator showFirstLastButtons="true"  [length]="dataSource.data.length" pageSize="20">
        </mat-paginator>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
