<vex-page-layout mode="card">
  <vex-page-layout-header class="flex flex-col items-start justify-center">
    <div class="container">
      <div>
        <h1 class="title mt-0 mb-1">Downloads</h1>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="container">
    <div class="card p-2 md:p-6">
      <div class="headline">
        <mat-icon svgIcon="mat:download" color="primary" class="cull-text-icon"></mat-icon>
        Install Cullable Desktop
      </div>
      <div class="mt-10">
        <cull-release-manifest [enableAdvancedOptions]="true"></cull-release-manifest>
      </div>

    </div>
  </vex-page-layout-content>
</vex-page-layout>
