import {Component, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'cull-login-error-popover',
  templateUrl: './login-error-popover.component.html',
  styleUrls: ['./login-error-popover.component.scss']
})
export class LoginErrorPopoverComponent implements OnInit {

  constructor(public auth: AuthService) { }

  ngOnInit(): void {
  }

}
