import {Component, OnInit} from '@angular/core';
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'cull-account-setup-required',
  templateUrl: './account-setup-required.component.html',
  styleUrls: ['./account-setup-required.component.scss']
})
export class AccountSetupRequiredComponent implements OnInit {

  constructor(private auth: AuthService) { }

  ngOnInit(): void {
  }

  logout() {
    this.auth.logout({returnTo: document.location.origin});
  }

}
