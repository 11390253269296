<div *ngIf="complete" class="text-center">
  <div class="my-10">
    <mat-icon class="icon-5xl" svgIcon="mat:thumb_up" color="primary"></mat-icon>
  </div>
  <div class="mb-10 subheading-1">
    We'll review your request and contact you shortly.
  </div>
  <a mat-button (click)="reset()">Reset</a>
</div>
<form #registerForm="ngForm" class="p-6" *ngIf="!complete">
  <div class="md:flex gap-4 mb-5">
    <div class="md:w-1/2">
      <mat-form-field>
        <mat-label>First Name</mat-label>
        <input [(ngModel)]="form.firstName" name="firstName" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last Name</mat-label>
        <input [(ngModel)]="form.lastName" name="lastName" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>E-Mail</mat-label>
        <input [(ngModel)]="form.email" name="email" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Phone</mat-label>
        <input [(ngModel)]="form.phone" name="phone" matInput required>
      </mat-form-field>
    </div>
    <div class="md:w-1/2">
      <mat-form-field>
        <mat-label>Company</mat-label>
        <input [(ngModel)]="form.company" name="company" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input [(ngModel)]="form.address" name="address" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input [(ngModel)]="form.city" name="city" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>State/Region</mat-label>
        <input [(ngModel)]="form.state" name="state" matInput required>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Postal Code</mat-label>
        <input [(ngModel)]="form.zip" name="zip" matInput required>
      </mat-form-field>
    </div>
  </div>
  <div class="text-center">
    <button class="mb-3" (click)="send()" color="primary" mat-raised-button type="button" [disabled]="busy || registerForm.form.invalid">
      REQUEST ACCESS
    </button>
    <p class="text-secondary">
      Already have an account?<br/>
      <a mat-button (click)="auth.loginWithRedirect()">Sign in here</a>
    </p>
  </div>
</form>
