import {Component, OnInit} from '@angular/core';
import {AccessRequestForm} from "../../../lib/model/access-request-form.model";
import {AccountService} from "../../../lib/services/account.service";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AuthService} from "@auth0/auth0-angular";

@Component({
  selector: 'cull-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  form: AccessRequestForm = {
    address: "",
    city: "",
    company: "",
    email: "",
    firstName: "",
    jobTitle: "",
    lastName: "",
    phone: "",
    state: "",
    zip: ""
  }
  busy = false;
  complete = false;

  constructor(public auth: AuthService, private accountService: AccountService, private snackbar: MatSnackBar) {
  }

  ngOnInit() {
  }

  send() {
    this.busy = true;
    this.accountService.requestAccess(this.form).subscribe({
      next: () => {
        this.complete = true;
      },
      error: err => {
        console.error("Unable to send request", err);
        this.snackbar.open("Unable to send request", "Close");
      },
      complete: () => {
        return this.busy = false;
      }
    });
  }

  reset() {
    this.complete = false;
  }
}
