import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {VexModule} from '../@vex/vex.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomLayoutModule} from './custom-layout/custom-layout.module';
import {CommonModule} from "@angular/common";
import {SidenavModule} from "../@vex/layout/sidenav/sidenav.module";
import {ConfigPanelModule} from "../@vex/components/config-panel/config-panel.module";
import {SidebarModule} from "../@vex/components/sidebar/sidebar.module";
import {QuickpanelModule} from "../@vex/layout/quickpanel/quickpanel.module";
import {HomeComponent} from './pages/home/home.component';
import {NavigationItemModule} from "../@vex/components/navigation-item/navigation-item.module";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatNativeDateModule, MatRippleModule} from "@angular/material/core";
import {NavigationModule} from "../@vex/layout/navigation/navigation.module";
import {LayoutModule} from "../@vex/layout/layout.module";
import {AuthHttpInterceptor, AuthModule} from "@auth0/auth0-angular";
import {ToolbarComponent} from "./lib/layout/toolbar/toolbar.component";
import {ToolbarUserComponent} from "./lib/layout/toolbar/toolbar-user/toolbar-user.component";
import {
  ToolbarUserDropdownComponent
} from "./lib/layout/toolbar/toolbar-user/toolbar-user-dropdown/toolbar-user-dropdown.component";
import {FooterComponent} from "./lib/layout/footer/footer.component";
import {ProductKeysComponent} from './pages/product-keys/product-keys.component';
import {environment} from "../environments/environment";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {PageLayoutModule} from "../@vex/components/page-layout/page-layout.module";
import {BreadcrumbsModule} from "../@vex/components/breadcrumbs/breadcrumbs.module";
import {MatTableModule} from "@angular/material/table";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {AddProductKeyComponent} from './pages/product-keys/add-product-key/add-product-key.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MatDialogModule} from "@angular/material/dialog";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatDividerModule} from "@angular/material/divider";
import {UsageReportComponent} from './pages/usage-report/usage-report.component';
import {ChartModule} from "../@vex/components/chart/chart.module";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatSelectModule} from "@angular/material/select";
import {BarChartModule} from "@swimlane/ngx-charts";
import {MatListModule} from "@angular/material/list";
import {RegisterComponent} from './pages/home/register/register.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FeaturesComponent} from './pages/home/features/features.component';
import {GettingStartedComponent} from './pages/docs/getting-started/getting-started.component';
import {MatPaginatorModule} from "@angular/material/paginator";
import {
  LoginErrorPopoverComponent
} from './lib/layout/toolbar/toolbar-user/login-error-popover/login-error-popover.component';
import {AccountSetupRequiredComponent} from './pages/errors/account-setup-required/account-setup-required.component';
import {HasAnyRoleComponent} from './lib/components/has-any-role/has-any-role.component';
import { ReleaseManifestComponent } from './lib/components/release-manifest/release-manifest.component';
import { DownloadsComponent } from './pages/downloads/downloads.component';

@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    ToolbarUserComponent,
    ToolbarUserDropdownComponent,
    FooterComponent,
    HomeComponent,
    ProductKeysComponent,
    AddProductKeyComponent,
    UsageReportComponent,
    RegisterComponent,
    FeaturesComponent,
    GettingStartedComponent,
    LoginErrorPopoverComponent,
    AccountSetupRequiredComponent,
    HasAnyRoleComponent,
    ReleaseManifestComponent,
    DownloadsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,

    // material
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatRippleModule,
    MatSnackBarModule,
    MatTableModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSelectModule,
    MatListModule,
    MatCheckboxModule,
    MatPaginatorModule,

    // auth0
    HttpClientModule,
    AuthModule.forRoot({
      domain: 'cullable.auth0.com',
      clientId: environment.authZeroClientId,
      audience: environment.authZeroAudience,
      httpInterceptor: {
        allowedList: [
          {
            uri: environment.apiUrl + "*",
            tokenOptions: {
              audience: environment.authZeroAudience
            }
          }
        ]
      }
    }),

    // Vex
    VexModule,
    CustomLayoutModule,
    CommonModule,
    LayoutModule,
    SidenavModule,
    ConfigPanelModule,
    SidebarModule,
    QuickpanelModule,
    NavigationModule,
    NavigationItemModule,
    PageLayoutModule,
    BreadcrumbsModule,
    ChartModule,
    ReactiveFormsModule,

    // ngx-charts
    BarChartModule,
  ],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
