import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {catchError, map, mergeMap, Observable, of} from 'rxjs';
import {AccountService} from "../services/account.service";

@Injectable({
  providedIn: 'root'
})
export class AccountGuard implements CanActivate {

  constructor(private accountService: AccountService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.accountService.authUser$.pipe(
      mergeMap(user => {
        if (!user) {
          return of(true);
        } else {
          // return of(true);
          return this.accountService.getAccount().pipe(
            catchError((err) => {
              console.error("Failed to retrieve account for user", user, err);
              return of(null);
            }),
            map(account => {
              if (account) {
                return true;
              } else {
                return this.router.createUrlTree(["/errors/account-setup-required"]);
              }
            })
          )
        }
      })
    );
  }


}
