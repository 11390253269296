import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {AccountService} from "../../../lib/services/account.service";
import {MatSnackBar} from "@angular/material/snack-bar";

@Component({
  selector: 'cull-add-product-key',
  templateUrl: './add-product-key.component.html',
  styleUrls: ['./add-product-key.component.scss']
})
export class AddProductKeyComponent implements OnInit {

  description: string = null;
  busy = false;

  constructor(
    public dialogRef: MatDialogRef<AddProductKeyComponent>,
    private accountService: AccountService,
    private snackbar: MatSnackBar) {
  }

  ngOnInit(): void {
  }

  save(): void {
    this.busy = true;
    this.accountService.addApiKey(this.description).subscribe({
      next: value => {
        this.dialogRef.close(value);
        this.busy = false;
      },
      error: error => {
        console.error('Unable to save key', error);
        this.snackbar.open('Unable to save key', 'Close')
        this.busy = false;
      }
    });

  }
}
