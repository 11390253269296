<ng-template #sidenavRef>
  <vex-sidenav [collapsed]="true"></vex-sidenav>
</ng-template>

<ng-template #toolbarRef>
  <cull-toolbar [hasShadow]="true"
               [mobileQuery]="!(isDesktop$ | async)"
               class="vex-toolbar"></cull-toolbar>
</ng-template>

<ng-template #footerRef>
  <cull-footer class="vex-footer"></cull-footer>
</ng-template>

<ng-template #quickpanelRef>
  <vex-quickpanel></vex-quickpanel>
</ng-template>

<vex-layout [footerRef]="footerRef"
            [quickpanelRef]="quickpanelRef"
            [sidenavRef]="sidenavRef"
            [toolbarRef]="toolbarRef">
  <router-outlet></router-outlet>
</vex-layout>

