<vex-page-layout mode="card">
  <vex-page-layout-header class="flex flex-col items-start justify-center">
    <div class="container">
      <div>
        <h1 class="title mt-0 mb-1">Getting Started</h1>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="container">
    <div class="card p-2 md:p-6">
      <div class="grid grid-cols-1 md:grid-cols-4 gap-10 py-6">
        <div class="headline">
          <mat-icon svgIcon="mat:download" color="primary" class="cull-text-icon"></mat-icon>
          Install
        </div>
        <div class="md:col-span-3">
          <p class="mb-5">Download and run the installer for your platform</p>
          <cull-release-manifest></cull-release-manifest>
        </div>
        <div class="md:col-span-4">
          <hr/>
        </div>
        <div class="headline">
          <mat-icon svgIcon="mat:settings" color="primary" class="cull-text-icon"></mat-icon>
          Configure
        </div>
        <div class="md:col-span-3">
          <p class="mb-10">
            Upon first run, the settings dialog will prompt you for a Product Key and Storage Location.
          </p>
          <div class="md:flex mb-7">
            <div class="font-bold flex-none w-36">Product Key:</div>
            <div class="grow">
              <p class="mb-5">
                The product key is required to identify the the application. Use the Product Key management page to provision keys:
              </p>
              <button mat-flat-button color="primary" [routerLink]="['/product-keys']">Product Key Manager</button>
            </div>
          </div>
          <div class="md:flex">
            <div class="font-bold flex-none w-36">Storage Location:</div>
            <div class="grow">
              This one should be easy. Unless instructed otherwise, the default value should be appropriate. Accept the default and that
              should wrap up the configuration settings.
            </div>
          </div>
        </div>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
