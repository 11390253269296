import {Component, OnInit} from '@angular/core';
import {AccountService} from "../../lib/services/account.service";
import {ProductKey} from "../../lib/model/product-key.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AddProductKeyComponent} from "./add-product-key/add-product-key.component";
import {MatDialog} from "@angular/material/dialog";
import {Clipboard} from "@angular/cdk/clipboard";

@Component({
  selector: 'cull-product-keys',
  templateUrl: './product-keys.component.html',
  styleUrls: ['./product-keys.component.scss']
})
export class ProductKeysComponent implements OnInit {
  loading = false;
  busy = false;
  keys: ProductKey[];
  maxKeysReached = false;
  maxKeys = 10;
  displayedColumns: string[] = ['created', 'description', 'value', 'action'];


  constructor(private accountService: AccountService,
              private clipboard: Clipboard,
              private snackbar: MatSnackBar,
              private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.loadProductKeys();
  }


  copyKeyValue(key: ProductKey) {
    this.clipboard.copy(key.id + "." + key.value);
    this.snackbar.open("Key copied to clipboard", 'Close', {duration: 500});
  }

  deleteKey(id) {
    this.busy = true;
    this.accountService.deleteApiKey(id).subscribe({
      next: () => {
        this.loadProductKeys();
        this.snackbar.open('Key removed', 'Close', {duration: 1000});
        this.busy = false;
      },
      error: error => {
        console.error('Unable to remove key', error);
        this.snackbar.open('Unable to remove key', 'Close');
        this.busy = false;
      }
    });
  }

  openAddKeyDialog() {
    const dialogRef = this.dialog.open(AddProductKeyComponent, {width: "600px"});
    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.loadProductKeys()
        }
      }
    });
  }

  private loadProductKeys() {
    this.loading = true;
    this.accountService.listApiKeys().subscribe({
      next: value => {
        this.loading = false;
        this.maxKeysReached = value.length >= this.maxKeys;
        this.keys = value;
      },
      error: error => {
        this.loading = false;
        console.error("Failed to list product keys", error);
        this.snackbar.open("Failed to list product keys", 'Close');
      }
    });
  }

}
