import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {ToolbarUserDropdownComponent} from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import {PopoverService} from "../../../../../@vex/components/popover/popover.service";
import {AuthService} from "@auth0/auth0-angular";
import {LoginErrorPopoverComponent} from "./login-error-popover/login-error-popover.component";

@Component({
  selector: 'cull-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {

  menuOpen: boolean;

  @ViewChild("userToolbar")
  userToolbar: HTMLElement;


  constructor(public auth: AuthService,
              private popover: PopoverService,
              private cd: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.auth.error$.subscribe({
      next: value => {
        console.log("auth error detected: ", value);
        this.showLoginErrorPopover();
      }
    })
  }

  showLoginErrorPopover() {
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: LoginErrorPopoverComponent,
      origin: this.userToolbar,
      offsetY: 16,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.cd.markForCheck();
    });
  }

  showUserMenuPopover() {
    this.menuOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: this.userToolbar,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.menuOpen = false;
      this.cd.markForCheck();
    });
  }
}
