import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {AccountService} from "../../lib/services/account.service";
import {OperationUsage, OperationUsageSeries, OperationUsageType} from "../../lib/model/operation-usage.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {DatePipe} from "@angular/common";
import {DateTime} from "luxon";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'cull-usage-report',
  templateUrl: './usage-report.component.html',
  styleUrls: ['./usage-report.component.scss'],
  providers: [DatePipe]
})
export class UsageReportComponent implements OnInit, AfterViewInit {
  loading = false;
  period: Date;
  nextPeriod: Date;
  prevPeriod: Date;

  pageOcr = OperationUsageType.PageOcr;
  pageOverlay = OperationUsageType.PageOverlay;
  pageImageAggregate = OperationUsageType.PageImageAggregate;
  entityExtraction = OperationUsageType.EntityExtraction;
  operationType = OperationUsageType.PageOcr;
  series: OperationUsageSeries[];
  records: OperationUsage[];
  dataSource = new MatTableDataSource<OperationUsage>();
  displayedColumns = ['period', 'label', 'quantity'];

  @ViewChild(MatPaginator) paginator: MatPaginator;


  constructor(private accountService: AccountService, private snackbar: MatSnackBar, private datepipe: DatePipe) {
  }

  ngOnInit(): void {
    this.loadReport(new Date());
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }


  protected formatResults(records: OperationUsage[]): OperationUsageSeries[] {
    const report: OperationUsageSeries[] = [];
    for (const r of records) {
      const val = {name: r.label, value: r.quantity};
      const period = this.datepipe.transform(r.period, "MM/dd/yy");
      const existing = report.find(value => value.name == period);
      if (existing) {
        existing.series.unshift(val);
      } else {
        report.unshift({
          name: period,
          series: [val]
        });
      }
    }
    return report;
  }

  loadReport(value: Date) {
    let dateTime = DateTime.fromJSDate(value).startOf("month");
    this.period = dateTime.toJSDate();
    this.prevPeriod = dateTime.minus({months: 1}).startOf("month").toJSDate();
    this.nextPeriod = dateTime.plus({months: 1}).startOf("month").toJSDate();
    this.loading = true;
    this.accountService.reportOperationUsage(this.period, this.operationType).subscribe({
      next: value => {
        this.records = value;
        this.dataSource.data = value;
        this.series = this.formatResults(value);
      },
      error: err => {
        console.error("Failed to retrieve report", err);
        this.snackbar.open("Failed to retrieve report", "Close");
      },
      complete: () => this.loading = false
    });
  }

}
