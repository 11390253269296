import {Component, OnInit} from '@angular/core';
import {fadeInUp400ms} from "../../../../@vex/animations/fade-in-up.animation";

@Component({
  selector: 'cull-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  animations: [
    fadeInUp400ms
  ]
})
export class FeaturesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
