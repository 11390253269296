import {Component, Input} from '@angular/core';
import {AccountService} from "../../services/account.service";
import {map, Observable} from "rxjs";

@Component({
  selector: 'cull-has-any-role',
  templateUrl: './has-any-role.component.html',
  styleUrls: ['./has-any-role.component.scss']
})
export class HasAnyRoleComponent  {


  @Input()
  roles: string[];

  constructor(private accountService: AccountService) {
  }


  displayContent(): Observable<boolean> {
    return this.accountService.user$.pipe(
      map(user => {
        if (!user || !user.roles) {
          return false;
        }
        for (const role of this.roles) {
          if (user.roles.indexOf(role) >= 0) {
            return true;
          }
        }
        return false;
      })
    );
  }

}
