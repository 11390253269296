<div [class.container]="isVerticalLayout$ | async" class="toolbar w-full px-gutter flex items-center">
  <button (click)="openSidenav()" [class.hidden]="!mobileQuery" mat-icon-button type="button">
    <mat-icon svgIcon="mat:menu"></mat-icon>
  </button>

  <a *ngIf="isVerticalLayout$ | async"
     [routerLink]="['/']"
     class="ltr:mr-4 rtl:ml-4 block flex items-center">
    <img alt="Logo" class="w-8 select-none" src="assets/img/demo/logo.svg"/>
    <h1 [class.hidden]="!mobileQuery" class="title ltr:pl-4 rtl:pr-4 m-0 select-none">Cullable</h1>
  </a>


  <div *ngIf="(isVerticalLayout$ | async) && isNavbarInToolbar$ | async"
       [class.hidden]="mobileQuery"
       class="px-gutter flex-none flex items-center">
    <vex-navigation-item *ngFor="let item of navigationItems" [item]="item"></vex-navigation-item>
  </div>

  <span class="flex-1"></span>

  <div class="-mx-1 flex items-center">
    <div *ngIf="userVisible$ | async" class="px-1">
      <cull-toolbar-user></cull-toolbar-user>
    </div>
  </div>
</div>

<vex-navigation *ngIf="(isVerticalLayout$ | async) && isNavbarBelowToolbar$ | async"
                [class.hidden]="mobileQuery"></vex-navigation>
