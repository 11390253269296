const environments = {
  local: {
    production: false,
    googleAuthClientId: '1065291638971-u4m6p86g41sm21pq3tqddpe3c1drb0s2.apps.googleusercontent.com',
    authZeroClientId: 'FmQJEh1dU8viDumfpylPIv34sT0jO1cK',
    authZeroAudience: 'https://api.dev.cullable.com',
    // apiUrl: 'http://localhost:8080',
    apiUrl: 'https://api.dev.cullable.com',
  },
  dev: {
    production: true,
    googleAuthClientId: '1065291638971-u4m6p86g41sm21pq3tqddpe3c1drb0s2.apps.googleusercontent.com',
    authZeroClientId: 'FmQJEh1dU8viDumfpylPIv34sT0jO1cK',
    authZeroAudience: 'https://api.dev.cullable.com',
    apiUrl: 'https://api.dev.cullable.com',
  },
  prod: {
    production: true,
    googleAuthClientId: '982613054687-anrast9fsp71nq155pkrsu92hgaq7ho3.apps.googleusercontent.com',
    authZeroClientId: 'uXDXX8uhaG8hnj6yBzdpJViNp57Mgast',
    authZeroAudience: 'https://api.cullable.com',
    apiUrl: 'https://api.cullable.com',
  }
};


export const environment = findConfig();

function findConfig() {
  const hostname = window && window.location && window.location.hostname;
  switch (hostname) {
    case ('localhost'):
      return environments.local;
    case ('desktop.dev.cullable.com'):
      return environments.dev;
    case ('desktop.cullable.com'):
      return environments.prod;
    default:
      throw new Error('Unknown hostname: ' + hostname);
  }
}
