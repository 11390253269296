<div class="cull-dropdown" *ngIf="auth.error$|async as error">
  <div class="cull-dropdown-header-warning flex items-center justify-between">
    <div class="flex items-center">
      <div class="cull-dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:error"></mat-icon>
      </div>
      <div class="cull-dropdown-heading">Login Error</div>
    </div>
  </div>
  <div class="cull-dropdown-content p-6">
    {{error.message}}
  </div>
</div>


