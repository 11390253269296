<div class="cull-dropdown" *ngIf="accountService.authUser$|async as user">
  <div class="cull-dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="cull-dropdown-heading-icon flex items-center justify-center">
        <mat-icon svgIcon="mat:person"></mat-icon>
      </div>
      <div class="cull-dropdown-heading">{{user.name}}</div>
    </div>
  </div>

  <div class="cull-dropdown-content">
    <cull-has-any-role [roles]="['ROLE_ACCOUNT_ADMIN', 'ROLE_SYSTEM_ADMIN', 'ROLE_SUPPORT_ADMIN']">
      <a (click)="close()" routerLink="/product-keys" class="notification flex items-center" matRipple>
        <mat-icon svgIcon="mat:key" color="primary" class="notification-icon flex-none"></mat-icon>
        <div class="flex-auto">
          <div class="notification-label">Product Keys</div>
          <div class="notification-description">Manage your product keys for users</div>
        </div>
        <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
      </a>
    </cull-has-any-role>
    <cull-has-any-role [roles]="['ROLE_ACCOUNT_ADMIN', 'ROLE_SYSTEM_ADMIN', 'ROLE_SUPPORT_ADMIN', 'ROLE_USER']">
      <a (click)="close()" routerLink="/usage-report" class="notification flex items-center" matRipple>
        <mat-icon svgIcon="mat:analytics" color="primary" class="notification-icon flex-none"></mat-icon>
        <div class="flex-auto">
          <div class="notification-label">Usage Reports</div>
          <div class="notification-description">Analyze your account's usage</div>
        </div>
        <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
      </a>
    </cull-has-any-role>
  </div>

  <div class="cull-dropdown-footer flex items-center justify-between">
    <a (click)="logout()" color="primary" mat-button>Logout</a>
  </div>
</div>


