<vex-page-layout mode="card">
  <vex-page-layout-header class="flex flex-col items-start justify-center">
    <div class="container">
      <div>
        <h1 class="title mt-0 mb-1">Account Setup</h1>
      </div>
    </div>
  </vex-page-layout-header>
  <vex-page-layout-content class="container">
    <div class="card p-2 md:p-6 text-center">
      <div class="my-10">
        <mat-icon class="icon-5xl cull-text-icon" svgIcon="mat:warning" color="warn"></mat-icon>
        Your account requires additional setup to proceed.
        <div class="mt-5">
          <button mat-flat-button color="primary" (click)="logout()">Logout</button>
        </div>
      </div>
    </div>
  </vex-page-layout-content>
</vex-page-layout>
