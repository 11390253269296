<div class="footer flex">
  <div class="flex-auto flex items-center container">
    <a class="action flex-none"
       href="https://platinumids.com/privacy/"
       id="get-vex"
       mat-flat-button>
      <mat-icon class="icon-sm ltr:mr-2 rtl:ml-2" svgIcon="mat:shopping_bag"></mat-icon>
      <span>Privacy Policy</span>
    </a>
    <div class="font-medium ltr:ml-4 rtl:mr-4 hidden sm:block">
      &copy; Copyright {{copyrightDate|date: 'yyyy'}} -
      <a href="https://platinumids.com/">Platinum Intelligent Data Solutions</a>, LLC.
    </div>
  </div>
</div>
