<form #keyForm="ngForm"  (ngSubmit)="save()">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">New Key</h2>
    <button class="text-secondary" [mat-dialog-close]="true" mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
  <mat-divider class="-mx-6 text-border"></mat-divider>
  <mat-dialog-content class="flex flex-col">
    <p class="app-v-padder">
      <mat-form-field class="w-full">
        <mat-label>Description</mat-label>
        <!--suppress HtmlUnknownAttribute -->
        <input cdkFocusInitial matInput required maxlength="32"
               #productKeyDescription="ngModel"
               [(ngModel)]="description"
               id="productKeyDescription"
               name="productKeyDescription">
        <mat-hint>Enter a description to help you identify the key.</mat-hint>
        <mat-error *ngIf="productKeyDescription.invalid">
          Please enter a description (max 32 characters)
        </mat-error>
      </mat-form-field>
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close="true">Cancel</button>
    <button mat-raised-button
            color="primary"
            (click)="save()"
            [disabled]="busy || keyForm.invalid">
      Save
    </button>
  </mat-dialog-actions>
</form>
